import "../assets/css/cards.css"
import React from 'react';
import {
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
} from 'reactstrap';

function Experience() {
    return (
    <>
        <Container>
            <TabContent>
                <TabPane>
                    <Row>
                        <Col md='12'>
                            <h2 className='text-center'>Work</h2>
                            <section className="card-section">
                                <div className="card-grid">
                                    <a className="card" href="https://www.man.eu" target="_blank">
                                        <div className="card__background" style={{backgroundImage: `url("https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoibWFuXC9hY2NvdW50c1wvY2ZcLzQwMDAwMjBcL3Byb2plY3RzXC8xODYwOFwvYXNzZXRzXC9lN1wvNDA0MDEwXC9mOGQ5ZmVhNmFmYmVhMTZiZWI1YTZmMGMzNzJmZTFjNi0xNjA1Njk0MzU2LmpwZyJ9:man:aoN8ep5tv1PZKxBtJQk3q5bykfqgu7hltbwrhSzvviQ?width=2400")`}} ></div>
                                        <div className="card__content">
                                            <p className="card__category">MAN Truck & Bus<br/>SE</p>
                                            <h3 className="card__heading">Project Management</h3>
                                            <p className="card__paragraph">2024 - today</p>
                                            <p className="card__paragraph">Developing Digital Automotive Services</p>
                                        </div>
                                        <div className="card__addon truck" style={{backgroundImage: `url("https://static.wixstatic.com/media/8a7a3a_952b65d055e84522b75cd021ef5dca68~mv2.png/v1/fill/w_458,h_384,al_c,lg_1,q_85,enc_auto/MANTRUCK.png")`}}></div>
                                    </a>
                                    <a className="card" href="https://www.man.eu" target="_blank">
                                        <div className="card__background" style={{backgroundImage: `url("https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoibWFuXC9hY2NvdW50c1wvY2ZcLzQwMDAwMjBcL3Byb2plY3RzXC8xODYwOFwvYXNzZXRzXC9lN1wvNDA0MDEwXC9mOGQ5ZmVhNmFmYmVhMTZiZWI1YTZmMGMzNzJmZTFjNi0xNjA1Njk0MzU2LmpwZyJ9:man:aoN8ep5tv1PZKxBtJQk3q5bykfqgu7hltbwrhSzvviQ?width=2400")`}} ></div>
                                        <div className="card__content">
                                            <p className="card__category">MAN Truck & Bus SE</p>
                                            <h3 className="card__heading">Software Engineer</h3>
                                            <p className="card__paragraph">2019 - 2024</p>
                                            <p className="card__paragraph">Developing Digital Automotive Services</p>
                                        </div>
                                        <div className="card__addon truck" style={{backgroundImage: `url("https://static.wixstatic.com/media/8a7a3a_952b65d055e84522b75cd021ef5dca68~mv2.png/v1/fill/w_458,h_384,al_c,lg_1,q_85,enc_auto/MANTRUCK.png")`}}></div>
                                    </a>
                                </div>
                            </section>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='12'>
                            <h2 className='text-center'>Apprenticeship & Internships </h2>
                            <section className="card-section">
                                <div className="card-grid">
                                    <a className="card" href="https://www.man.eu" target="_blank">
                                        <div className="card__background" style={{backgroundImage: `url("https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoibWFuXC9hY2NvdW50c1wvY2ZcLzQwMDAwMjBcL3Byb2plY3RzXC8xODYwOFwvYXNzZXRzXC9lN1wvNDA0MDEwXC9mOGQ5ZmVhNmFmYmVhMTZiZWI1YTZmMGMzNzJmZTFjNi0xNjA1Njk0MzU2LmpwZyJ9:man:aoN8ep5tv1PZKxBtJQk3q5bykfqgu7hltbwrhSzvviQ?width=2400")`}} ></div>
                                        <div className="card__content">
                                            <p className="card__category">MAN Truck & Bus SE</p>
                                            <h3 className="card__heading">Apprentice</h3>
                                            <p className="card__paragraph">Software Developer</p>
                                        </div>
                                        <div className="card__addon truck" style={{backgroundImage: `url("https://static.wixstatic.com/media/8a7a3a_952b65d055e84522b75cd021ef5dca68~mv2.png/v1/fill/w_458,h_384,al_c,lg_1,q_85,enc_auto/MANTRUCK.png")`}}></div>
                                    </a>
                                    <a className="card" href="https://www.mobility.siemens.com/" target="_blank">
                                        <div className="card__background" style={{backgroundImage: `url("https://assets.new.siemens.com/siemens/assets/api/uuid:f97ed8ba-64ff-4710-87f7-0684af1a75bf/width:1266/crop:0:0,449:0,99893:0,351/quality:high/im2018080612mo_300dpi.jpg")`}}></div>
                                        <div className="card__content">
                                            <p className="card__category">Siemens AG<br/>Mobility Solutions</p>
                                            <h3 className="card__heading">Internship</h3>
                                            <p className="card__paragraph">Network Administration</p>
                                        </div>
                                        <div className="card__addon train" style={{backgroundImage: `url("https://assets.new.siemens.com/siemens/assets/api/uuid:91fa47e1-c579-4908-ae04-6bbcbe1bfc13/width:1125/quality:high/mireo-plus-h.png")`}}></div>
                                    </a>
                                    <a className="card" href="https://www.bundeswehr.de/en/organization/german-air-force" target="_blank">
                                        <div className="card__background" style={{backgroundImage: `url("https://www.fr.de/bilder/2022/03/09/91399966/28408035-ein-eurofighter-der-deutschen-luftwaffe-symbolbild-1QyCf2zoTKea.jpg")`}}></div>
                                        <div className="card__content">
                                            <p className="card__category">Bundeswehr</p>
                                            <h3 className="card__heading">Internship</h3>
                                            <p className="card__paragraph">Aeromedical Institute of the German Federal Armed Air Force</p>
                                        </div>
                                        <div className="card__addon helicopter" style={{backgroundImage: `url("https://image.jimcdn.com/app/cms/image/transf/dimension=456x10000:format=png/path/sa523a094d1510991/image/i048c58189cc98ca9/version/1614505030/image.png")`}}></div>
                                    </a>
                                    <a className="card">
                                        <div className="card__background" style={{backgroundImage: `url("https://images.unsplash.com/photo-1526634140919-468dc3ae3870?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80")`}}></div>
                                        <div className="card__content">
                                            <p className="card__category">Local Repairshop</p>
                                            <h3 className="card__heading">Internship</h3>
                                            <p className="card__paragraph">Car Mechanic</p>
                                        </div>
                                    </a>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </Container>
    </>
    );
}

export default Experience;