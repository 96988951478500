import "../assets/css/paragraph.css"
import profil_pic from "../assets/img/profile_pic.jpg"
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

class SectionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = { age: 0 };
  }

  render() {
    return (
      <>
        <Container>
          <div className='owner'>
            <div className='avatar'>
              <img
                alt='profile picture'
                className='img-circle img-no-padding img-responsive'
                src={profil_pic}
              />
            </div>
            <div className='name'>
              <h2>About me</h2>
            </div>
          </div>
          <Row>
            <Col md='12'>
              <h5 className='text-center'>
              I am a{' '}
                {new Date().getMonth() >= 5
                  ? (this.state.age =
                      new Date().getFullYear() - 1998 + ' year old ')
                  : (this.state.age =
                      new Date().getFullYear() - 1999 + ' year old ')}
                Software Engineer, based in
                {'  '}
                <i className="fa-solid fa-location-dot"/>
                <i>Munich, Germany</i>
              </h5>
            </Col>
            <Col md="2"></Col>
            <Col className='text-center' md='8' >
              <h5 className='text-block'>
                Technology of all kinds is an important part of our lives and we can't imagine to life without it.
                The limitless possibilities and freedom are the keys to why I am fascinated by it every day. 
              </h5>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SectionOne;
